.projects {
    padding-bottom: 75px;
    padding-top: 50px;
}

.projectImage {
    width: 50vw;
    border: solid 2px black;
    margin: 20px;
}

.repositoryLink:hover {
    border-color: rgb(0, 152, 169);
    transition: 400ms;
}

.projectText {
    width: 50vw;
}

.projectTitle {
    text-align: center;
}

.projectTitle:before {
    content: "";
    display: inline-block;
    width: 30vw;
    height: 3px;
    background: #828282;
    left: 10vw;
    position: absolute;
    transform: translate(0, 13px);
  }

.projectTitle:after {
    content: "";
    display: inline-block;
    width: 30vw;
    height: 3px;
    background: #828282;
    right: 10vw;
    position: absolute;
    transform: translate(0, 13px);
}

.mobile.projectTitle:before {
    width: 20vw;
  }

.mobile.projectTitle:after {
    width: 20vw;
}

.repositoryLink {
    padding: 5px;
    border: solid 2.5px black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.subheaderDescription {
    font-size: 1em;
}

.smallGit {
    width: 20px;
    padding: 5px;
}