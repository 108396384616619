

.home {
    padding-top: 67px;
    display: block;
}

.home > * {
    margin-left: 30px;
    margin-right: 30px;
}

.intoH {
    color: #323232;
    width: max-content;
    border-bottom: 1px solid #eaecef;
    padding-bottom: 0.2em;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
}

.subHeader {
    margin-top: 0.2em;
    margin-bottom: 0.5em;
    color: #474849;
}

.face-container-desktop {
    width: 100%;
    min-width: 350px;
    min-height: 150px;
    padding-bottom: 150px;
}

.face-container.mobile {
    width: 100%;
    min-width: 150px;
    min-height: 150px;
    margin-left: 0px;
    margin-right: 0px;
}

.face-container-desktop > p {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 43vw;
    margin-left: 5vw;
    font-size: 1.4em;
}

.face-container-desktop > h1 {
    padding-left: 0px;
    padding-right: 0px;
}

.face-container.desktop > h2 {
    padding-left: 0px;
    padding-right: 0px;
}

.face-container.mobile > h1 {
    padding-left: 20px;
    padding-right: 20px;
}

.face-container.mobile > p {
    padding-left: 20px;
    padding-right: 20px;
}

.face-container.mobile > h2 {
    padding-left: 20px;
    padding-right: 20px;
}

@keyframes swing{
    0%{
        transform: rotate(-3deg);
    }
    50%{
        transform: rotate(3deg);
    }
    100%{
        transform: rotate(-3deg);
    }
}

.lozenge, .lozenge * {
    box-sizing: border-box;
    position: absolute;
}

.lozenge {
    margin-left: max(57vw, 175px);
    margin-top: -325px;
    z-index: -1;
}

.lozenge.mobile {
    margin-top: 0vw;
}

.home.mobile {
    padding-top: 0px;
}

/* add max toon to lozenge */

.face-image.mobile:before {
    content: '' ;
    display:block;
    float: right;
    height: 40vw;
    width: 40vw;
}


.leftHomeIntro {
    display: flex;
    flex-direction: column;
    padding-top: max(10vw, 100px);
}

.leftHomeIntro > * {
    margin-left: 10vw;
    padding-right: 0px;
    margin-right: 43vw;
}

.leftHomeIntro > p {
    font-size: 1.4em;
}



.lozenge {
    width: 40vw;
    height: 40vw;
    max-width: 500px;
    max-height: 500px;
    border-style: solid;
    border-color: #fff;
    border-width: min( 2vw, 25px);
    border-radius: 50%;
    overflow: hidden;
    background-color: #88c7e4;
    animation: swing 3.5s ease-in-out forwards infinite;
}
 
@keyframes swish{
    0%{
        border-radius: 58% 16% 20% 8% / 11% 29% 11% 16%;
    }
    50%{
        border-radius: 58% 16% 10% 20% / 11% 29% 20% 29%;
    }
    100%{
        border-radius: 58% 16% 20% 8% / 11% 29% 11% 16%;
    }
}

.head {
    width: 36vw;
    height: 36vw;
    max-width: 450px;
    max-height: 450px;
}

.hair {
    width: 21.5vw;
    height: 25vw;
    max-width: 268.8px;
    max-height: 312.5px;
    background-color: #4a2b0a;
    left: 50%;
    top: 58%;
    transform: translate(-50%, -65%);
    /* border-radius: 58% 16% / 11% 29%; */
    border-radius: 58% 16% 58% 16% / 11% 29% 11% 29%;

    border: 2px solid black;
    animation: swish 3.5s ease-in-out forwards infinite;
}

.ears {
    left: 50%;
    top: 57%;
    transform: translate(-50%, -50%);
    height: 8vw;
    max-height: 100px;
    width: 25vw;
    max-width: 312.5px;
}

.ears .ear {
    background-color: #eecbb4;
    border-radius: 50%;
    width: 5vw;
    max-width: 62.5px;
    height: 7.5vw;
    max-height: 93.8px;
    transform: rotate(-15deg);
    border: 2px solid black;
}

.ears .ear::before, .ears .ear::after {
    content: " ";
    width: 4vw;
    max-width: 50px;
    height: 6vw;
    max-height: 75px;
    background-color: #dfa59b;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
}

.ears .ear::after {
    width: 2vw;
    max-width: 25px;
    height: 3.5vw;
    max-height: 43.8px;
    background-color: #eecbb4;
    left: 35%;
    top: 35%;
    transform: rotate(15deg);
}

.ears .ear.ear-right {
    transform: rotate(15deg);
    right: 0;
}

.ears .ear.ear-right::after {
    left: 26%;
    transform: rotate(-15deg);
}

.face {
    width: 19.5vw;
    max-width: 243.8px;
    height: 27.5vw;
    max-height: 343.8px;
    background-color: #eecbb4;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10vw;
}

.sideburns {
    left: 50%;
    top: 39%;
    transform: translate(-50%, -50%);
    width: 20.5vw;
    max-width: 256.3px;
    height: 10vw;
    max-height: 125px;
    position: relative;
    overflow: hidden;
}

.sideburns::before, .sideburns::after {
    position: absolute;
    left: 0;
    top: 0;
    border-color: transparent;
    border-left-color: #4a2b0a;
    border-style: solid;
    content: " ";
    border-width: 2vw 2vw 8vw;
    transform: rotate(-4deg);
}

.max-toon.sideburns::before, .max-toon.sideburns::after {
    border-width: 25px 25px 100px;
}

.sideburns::after {
    left: auto;
    right: 0;
    border-left-color: transparent;
    border-right-color: #4a2b0a;
    transform: rotate(4deg);
}

.beard {
    width: 11vw;
    max-width: 137.5px;
    height: 10vw;
    max-height: 125px;
    /* background-color: #e8c5ae; */
    background-color: #eecbb4;
    left: 50%;
    top: 57%;
    transform: translate(-50%, 2.5vw);
    border-radius: 50%;
    overflow: hidden;
}

.max-toon.beard {
    transform: translate(-50%, 31.3px);
}

.mole {
    top: 10%;
    left: 73%;
    background-color: #a86e4c;
    width: 0.5vw;
    max-width: 6.3px;
    height: 0.5vw;
    max-height: 6.3px;
    border-radius: 100%;
}

.mouth {
    position: relative;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -40%);
    width: 9vw;
    max-width: 112.5px;
    height: 5vw;
    max-height: 62.5px;
    background-color: #7d3126;
    border-radius: 2vw 2vw 5vw 5vw;
    overflow: hidden;
    border: 1px solid black;
}

.mouth .tongue {
    background-color: #d96269;
    width: 15vw;
    max-width: 187.5px;
    height: 5vw;
    max-height: 62.5px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    border: 1px solid black;
}

.mouth .teeth {
    background-color: #fff;
    width: 6vw;
    max-width: 75px;
    height: 2.5vw;
    max-height: 31.3px;
    left: 50%;
    top: -30%;
    border-radius: 2.5vw;
    transform: translateX(-50%);
    border: 1px solid black;
}

.eyes {
    width: 17vw;
    max-width: 212.5px;
    height: 7vw;
    max-height: 87.5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
}

.eyes .eye {
    background-color: rgba(255, 255, 255, 0.8);
    width: 35%;
    height: 90%;
    left: 2.5%;
    border-radius: 3.5vw;
    border: 1.5px solid black;
}

.eyes .eye .brow {
    top: -3vw;
    width: 5.5vw;
    max-width: 68.8px;
    height: 2vw;
    max-height: 25px;
    background-color: #875e33;
    transform: rotate(-8deg);
    border: 1px solid black;
    border-top-left-radius: 80%;
}

.eyes .eye .pupil {
    left: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    width: 2.5vw;
    max-width: 32px;
    height: 2.5vw;
    max-height: 32px;
    border-radius: 50%;
}

.eyes .eye.eye-right {
    left: auto;
    right: 2.5%;
}

.eyes .eye.eye-right .brow {
    transform: rotate(8deg);
    left: auto;
    right: 0;
    border-top-left-radius: 0%;
    border-top-right-radius: 80%;
}

.eyes .eye.eye-right .pupil {
    left: 40%;
}

.nose {
    left: 50%;
    top: 39%;
    transform: translate(-50%, 100%);
    background-color: #dfa59b;
    width: 4.5vw;
    max-width: 56.3px;
    height: 4.5vw;
    max-height: 56.3px;
    border-radius: 5vw;
}

.nose::before {
    position: absolute;
    content: " ";
    border-color: transparent;
    border-bottom-color: #dfa59b;
    border-width: 0 1.5vw 5vw 1.5vw;
    width: 1.5vw;
    max-width: 18px;
    height: 0;
    border-style: solid;
    /* top: -2.5vw; */
    top: -75%;
}

.nose.max-toon::before {
    border-width: 0 18.8px 62.5px 18.8px;
}

.nose::after {
    position: absolute;
    background-color: #dfa59b;
    content: " ";
    width: 1.6vw;
    max-width: 19px;
    height: 1vw;
    max-height: 12.5px;
    /* top: -2.3vw; */
    top: -85%;
    border-radius: 50% 50% 0 0;
    left: 50%;
    transform: translateX(-50%);
}

.quiff {
    left: 50%;
    top: -2vw;
    transform: translateX(-50%);
    width: 16.5vw;
    max-width: 206.3px;
    height: 6.5vw;
    max-height: 81.3px;
    border-radius: 85% 85% 10% 10%;
    background-color: #4a2b0a;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

.quiff.max-toon {
    top: -25px;
}