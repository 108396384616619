
.resume {
    display: flex;
    flex-direction: column;
    padding: 7.5vw;
}

.mobile-resume {
    display: flex;
    flex-direction: column;
    padding: 7.5vw;
}

.mobile-resume > :last-child {
    align-self: center;
}

.resume-link {
    font-size: 1.6em;
    text-decoration: underline;
}

.resume-blurb1 {
    font-size: 1.5em;
}