
.nav {
    width: 100%;
    position: fixed;
    top: 0px;
    background-color: #fafafa;
    border-bottom: solid rgb(160, 167, 176) 1px;
    display: flex;
    justify-content: space-between;
}

.nameLink {
    display: flex;
    align-items: center;
}

.nameLink > * {
    font-size: 2.5em;
    padding-left: 10px;
}

.navbar {
    display: flex;
    list-style: none;
    flex-direction: row ;
    justify-content: flex-end;
    gap: 40px;
    align-items: center;
    padding-right: 25px;
}

.navbar li {
    height: 35px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    display: flex;
}

.hoverActive {
    opacity: 100%;
}

.hoverInactive {
    opacity: 0.60;
}

.linkedIn {
    height: 25px;
    filter: grayscale(100%) brightness(0);
    margin-right: -5px;
}

.gitHub {
    height: 32px;
    margin-left: -5px;
}

.menuLine {
    width: 113px;
    background: #000;
    position: absolute;
    top: 35px;
    left: 0;
    width: 101px;
    height: 2px;
    transition: all .6s ease;
}

.underlineSpan {
    position: absolute;
    border-bottom: 4px solid transparent;
    z-index: -1;
    transition: all .35s ease-in-out;
}